import React, { useContext, useEffect, useState} from 'react'
import "./WishList.scss"
import { MdClose, MdDelete } from 'react-icons/md';
import MyContext from '../../Context/MyContext';
import { AiOutlineHeart } from "react-icons/ai";
import Loader from '../../Loader/Loader';
import axios from 'axios';


const WishList = () => {
  const [loading,setLoading]=useState(true)

  const {showWishList,url,wish,handleNavigate,closeModal,setWish,removeProductFromWish,Navigate,token} = useContext(MyContext);

  

// for wishitems fetching start
useEffect(() => {


  const fetchWishItems = async () => {

    if(!token){
      setLoading(false)
      return
    }

    try {
 const {data} = await axios.get(`${url}/wish`, { 
  headers: {
    Authorization: `Bearer ${token}`,
  },
})
    
      setWish(data.wishInfo);
      sessionStorage.setItem('wish', JSON.stringify(data.wishInfo));
    } catch (error) {
      console.error('Failed to fetch account details:', error.response?.data?.error || error.message);
    }finally {
      setLoading(false);
    }
  };

  fetchWishItems();
}, [setWish,token,url]);
// for wishitems fetching end



if(loading){
  <p>loading...</p>
}
  
  return (

    <>
   

    { showWishList &&
    <div className='wish-model'>

        

        <div className="wish-content">
        <Loader/>

        <div className="close" onClick={closeModal}><MdClose/></div>

        <div className='wish-center'>


      {
         wish && wish.length>0 ?(

wish
.sort((a,b)=>b._id.localeCompare(a._id))
.map((Item) =>{
  return(

    <div className="wish-product" >

    
<div className="img-container" >
    <img src={Item.productimg} alt=""  onClick={()=>handleNavigate(Item.categoryid,Item.productid)}/>
</div>

<div className="prod-details">
    <span className="name">{Item.productname}</span>
    <span className="name">{Item.productprice}</span>

       

    

        
    
</div>

<span className='delete' onClick={() =>removeProductFromWish(Item.categoryid,Item.productid)}><MdDelete/></span>
</div>

  )
})):(

<div className='no-wish'>

<span className='empty-wish'><AiOutlineHeart /></span>

<span className='empty-text'>You don't have any products in the wishlist yet.
  <br />
You will find a lot of interesting products on our "Shop" page.</span>

<button className='cart_btn' onClick={()=> Navigate('/') || closeModal()}>Continue Shopping</button>

</div>
)


        }
      

 


        
        </div>

           

        </div>


    </div>
      }
    </>
  )
}

export default WishList;