import React, { useContext } from 'react';
import './PaymentForm.scss';
import LoginError from '../../../Common/LoginError/LoginError';
import MyContext from '../../../Common/Context/MyContext';
import axios from 'axios';

const Payment = () => {
  const { token, TotalValue, url,setMainloader } = useContext(MyContext);

  const handlePayment = async () => {
    try {
      setMainloader(true)
      const { data } = await axios.post(
        `${url}/create-payment`,
        {
          amount: TotalValue,
          orderId: `order_${new Date().getTime()}`,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        }
      );

      if (data.success) {
        window.location.href = data.data; 
      } else {
        alert('Failed to initiate payment. Please try again.');
      }
    } catch (error) {
      console.error('Payment Error:', error.response?.data?.error || error.message);
      alert('An error occurred during payment. Please try again.');
    }finally{
      setMainloader(false)
    }
  };

  return (
    <>
      {token ? (
        <div className="payment-container">
          <h3>Total Value: &#8377;{TotalValue}</h3>
          <button onClick={handlePayment}>Pay Now</button>
        </div>
      ) : (
        <LoginError title="Payment information" />
      )}
    </>
  );
};

export default Payment;
