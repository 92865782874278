import { useState } from "react";
import { RiArrowLeftSFill, RiArrowRightSFill } from "react-icons/ri";
import './Banner.scss'
import img1 from '../../../assets/banner/1.png'
import img2 from '../../../assets/banner/2.png'


const Slider = () => {
  
  const[mi,setMi]=useState(img1)
  const[ip,setIp]=useState(1)

  const handleNext = () =>{
if(mi===img1){

  setIp(0)
  setTimeout(() => {
    setMi(img2)
    setIp(1)
  }, 500);
}
else{
 
  setIp(0)
  setTimeout(() => {
    setMi(img1)
    setIp(1)
  }, 500);
}
  }

  const handleprev = () =>{
    if(mi===img1){
     
      setIp(0)
  setTimeout(() => {
    setMi(img2)
    setIp(1)
  }, 500);
    }
    else{
      
      setIp(0)
  setTimeout(() => {
    setMi(img1)
    setIp(1)
  }, 500);
    }
      }


  return (
    <div className="banner-main" style={{opacity:ip}}>
    
       
      <div className="Arrow"  onClick={handleprev}>
      <RiArrowLeftSFill />
      </div>
      <div className="Arrow" onClick={handleNext} >
      <RiArrowRightSFill />
      </div>

          <div className="Slide" >
            <div className="ImgContainer">
              <img src={mi} alt=""/>
            </div>
            <div className="InfoContainer">
            {mi===img1?
              <div className="Title">MODERN WEAR</div>:
              <div className="Title">CLASSIC WEAR</div>
            }

           
              <div className="Desc">DON'T COMPROMISE ON STYLE! <br /> GET FLAT 30% OFF FOR NEW ARRIVALS.</div>
            
              <span className="Button">SHOP NOW</span>
            </div>
          </div>
     
     
    
      </div>
  );
};

export default Slider;