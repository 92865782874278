import "./CartItem.scss";
import { MdClose } from "react-icons/md";
import { useContext,useEffect,useState } from "react";
import { BsCartX } from "react-icons/bs";
import MyContext from "../../../Context/MyContext";
import axios from "axios";

const CartItem = () => {
  const [loader, setLoader] = useState(true);
  const {
    Navigate,
    closeModal2,
    url,
    setCartItems,
    cartItems,
    token,
    handleNavigate,
    removeProductFromCart,
    handleIncreaseQuantity,
    handleDecreaseQuantity,
  } = useContext(MyContext);
  
// for cartitems fetching start
useEffect(() => {

  const fetchCartItems = async () => {

    
  if(!token){
    setLoader(false);
    return
  }

    try {
      const {data} = await axios.get(`${url}/cart`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    
      setCartItems(data.cartInfo);
      sessionStorage.setItem('cart', JSON.stringify(data.cartInfo));
    }  catch (error) {
      console.error('Failed to fetch cart details:', error.response?.data?.error || error.message);
    } finally {
      setLoader(false);
    }
  };

  fetchCartItems();
}, [setCartItems,token,url]);
// for cartitems fetching end
  


if (loader) {
  return <div>Loading...</div>;
}


  return (
  <div className="cart-products">
  
  {
    cartItems && cartItems.length > 0 ?(
     
     
cartItems
.sort((a,b)=>b._id.localeCompare(a._id))
.map((Item)=>{
  return(
    <div  className="cart-product">
             
    <div className="img-size">
      <div className="img-container" >
        <img src={Item.productimg} alt=""  onClick={()=>handleNavigate(Item.categoryid,Item.productid)} />
      </div>
      <span>{Item.size}</span>
    </div>

    <div className="prod-details">
      <span className="name">{Item.productname}</span>

      <MdClose
        className="close-btn"
        onClick={() =>removeProductFromCart(Item.categoryid,Item.productid,Item.size)
        }
      />

      <div className="quantity-buttons">
        <span onClick={() => handleDecreaseQuantity(Item.categoryid, Item.productid, Item.size)}>
          -
        </span>
        <span>{Item.quantity}</span>
        <span onClick={() => handleIncreaseQuantity(Item.categoryid, Item.productid, Item.size)}>
          +
        </span>
      </div>

      <div className="text">
        <span>{Item.quantity}</span>
        <span>x</span>
        <span className="highlight"> &#8377;{Item.productprice}</span>
      </div>
    </div>

</div>

  )
 
})
    ) :
     (
      <div className="no-item">
      <span>
        <BsCartX size={60} />
      </span>
      <h3>No Item In Cart</h3>
      <button
        className="add-to-cart-button"
        onClick={() => Navigate("/") || closeModal2()}
      >
        Go For Shopping
      </button>
    </div>

    )
  }
  </div>
  )
}

export default CartItem;
