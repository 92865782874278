import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './Name.scss'
import MyContext from '../../../../Context/MyContext';
import LoginError from '../../../../LoginError/LoginError';
import { FaArrowLeft } from "react-icons/fa";
import axios from 'axios';

const Name = () => {
  const { token,userdata,url,edit,setEdit, setUserdata,setMainloader,mainloader,setOpen,setMessage} = useContext(MyContext);
  const [loader, setLoader] = useState(true);

 
  
  useEffect(() => {

    const fetchUserData = async () => {
      if (!token) {
        setLoader(false);
        return;
      }
    
      try {
        const { data } = await axios.get(`${url}/account-details`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        setUserdata(data.accountInfo);
        sessionStorage.setItem('userdata', JSON.stringify(data.accountInfo));
      } catch (error) {
        console.error('Failed to fetch account details:', error.response?.data?.error || error.message);
      } finally {
        setLoader(false);
      }
    };
    fetchUserData();
  }, [token,setUserdata,url]);

  if (loader) {
    return <div>Loading...</div>;
  }



  return (
    <>
      {token ? (

       edit?
        <Formik
          initialValues={{
            name: userdata.name || '',
            email: userdata.email || '',
            mobile: userdata.mobile || '',
            password: '',
          }}
          validationSchema={ Yup.object({
            name: Yup.string().required('Name is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            mobile: Yup.string().required('Mobile number is required'),
            password: Yup.string().required('Password is required'),
          })}


         onSubmit={async (values) => {
  setMainloader(true);
  document.querySelector('body').style.overflow = "hidden";

  try {
    const { data } = await axios.post(`{url}/update-account-data`, values, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (data.success) {
      setUserdata(data.accountInfo);
      sessionStorage.setItem('userdata', JSON.stringify(data.accountInfo));
      setMessage(data.message);
      setOpen(true);
    } else {
      setMessage(data.error);
      setOpen(true);
    }
  } catch (error) {
    console.error('Error updating account data:', error.response?.data?.error || error.message);
    setMessage('An error occurred. Please try again.');
    setOpen(true);
  } finally {
    document.querySelector('body').style.overflow = "auto";
    setMainloader(false);
  }
}}

    
         
          
        >
          {() => (
            <Form className='name-main'>
            <div className='form_head'>
          <span onClick={() =>setEdit(false)}> <FaArrowLeft /></span> 
            <h2>update Account Details</h2>
            <div className='name_change'>(name,mobile,password)</div>
            </div>
            <div className='name-form'>
              <div className='name-input'>
                <label htmlFor="name">Name:</label>
                <Field type="text" name="name" />
                <ErrorMessage name="name" component="div" className="error-message"/>
              </div>
                 <div className='name-input'>
                <label htmlFor="email">Email:</label>
                <Field type="text" name="email" disabled/>
                <ErrorMessage name="email" component="div" className="error-message"/>
              </div>
                 <div className='name-input'>
                <label htmlFor="mobile">Mobile:</label>
                <Field type="text" name="mobile" />
                <ErrorMessage name="mobile" component="div" className="error-message"/>
              </div>
                 <div className='name-input'>
                <label htmlFor="password">Password:</label>
                <Field type="password" name="password" placeholder='enter Updated Password'/>
                <ErrorMessage name="password" component="div" className="error-message"/>
              </div>
              <button type="submit" disabled={mainloader}>
               {mainloader?'wait...':'Submit'} 
              </button>
              </div>
            </Form>
          )}
        </Formik>:
        <>
        <div className='alt_details'>
        <p><label>Name:</label> {userdata && userdata.name}</p>
        <p><label>Email:</label> {userdata && userdata.email}</p>
        <p><label>Mobile:</label> {userdata && userdata.mobile}</p>
        <p><label>Password:</label> ***********</p>
        <button onClick={() =>setEdit(true)}>update</button>
        </div>
     
        </>
      ) : (
        <LoginError title="Account information" />
      )}
    </>
  );
};

export default Name;
