// Checkout.js

import React, { useContext} from 'react';
import './Checkout.scss';
import ShippingForm from './ShippingForm/ShippingForm';
import MyContext from '../../Common/Context/MyContext';
import LoginError from '../../Common/LoginError/LoginError';



const Checkout = ({show}) => {

  const{TotalValue,token}=useContext(MyContext)


  return (

    <>
{
    token?

    <div className='checkout-main'>
   
   
    <div className="checkout-container">
    

   <ShippingForm show={show} />
     
      
    </div>


    {show &&
    <div className='checkout-price'>
    <h1>Price Details</h1>

    <div className='total-value'><span>Item Price</span> <span>&#8377;{TotalValue}</span></div>
    <div className='total-value'><span>Delivery Charges</span> <span>Free</span></div>
    <div className='total-value'><span>Total Payable</span> <span>&#8377;{TotalValue}</span></div>
    </div>
    }
   
     </div>:
     <LoginError title="Shipping information" />

    }
     </>



    
  );
};

export default Checkout;
