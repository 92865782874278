import Banner from "./Banner/Banner";
import Category from "./Category/Category";
import "./Home.scss";
import React from 'react';
import ProductCategory from "../Category/Category";

const Home = () => {
  
   
    return(
         <>
  <Banner/>
        
        <div className="main-content">

       
           
               
                <Category  />
              

            <ProductCategory/>

            

              

         
        </div>

      

     

    </>
    )
};

export default Home;
