import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../components/Home/Home";
import Category from "../components/Category/Category";
import SingleProduct from "../components/SingleProduct/SingleProduct";
import ScrollToTop from "react-scroll-to-top";
import Conatct from "../components/Contact/Conatct";
import GoToTop from "../Common/GoToTop/UseLocation-up";
import Checkout from "../components/Checkout/Checkout";
import NoPage from "../components/NoPage/NoPage";
import { ImArrowUp } from "react-icons/im";
import WishList from "../Common/Header/WishList/WishList";
import Cart from "../Common/Header/Cart/Cart";
import Header from "../Common/Header/Header";
import Loader from "../Common/Loader/Loader";
import MyContextProvider from "../Common/Context/MyContextProvider";
import Newsletter from "../Common/Footer/Newsletter/Newsletter";
import Footer from "../Common/Footer/Footer";
import Search from "../Common/Header/Search/Search";
import Alert from "../Common/Alert/Alert";
import Login from "../Common/Header/Loginuser/Login/Login";
import Name from "../Common/Header/Loginuser/Account/Account-details/Name";
import Account from "../Common/Header/Loginuser/Account/Account";
import LogoutModal from "../Common/Header/Loginuser/Account/LogoutModal/LogoutModal";
import PaymentForm from "../components/Checkout/PaymentForm/PaymentForm";
import Order from "../Common/Header/Loginuser/Account/Order/Order";
import ConfirmationPage from "../components/Checkout/ConfirmationPage/ConfirmationPage";
import Terms from "../Common/Footer/Tearm/Tearm";







const App = () => {




  return (


    <BrowserRouter>

      <MyContextProvider>
        <Loader />
        <Cart />
        <Alert/>
        <Search />
        <WishList />
        <Login/>
        <LogoutModal/>
        <Header />
        <Routes>
        

        
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Conatct />} /> 
          <Route path="/account-details" element={<Name />} /> 
          <Route path="*" element={<NoPage />} />
          <Route path="/category/:title" element={<Category />} />
          <Route path="/category/:title/:name" element={<SingleProduct />} />
          <Route path="/checkout" element={<Checkout show={true} />} />
          <Route path="/shipping-info" element={<Checkout show={false}/>} />
          <Route path="/account" element={<Account />} />
          <Route path="/payment" element={<PaymentForm />} /> 
          <Route path="/order" element={<Order />} /> 
          <Route path="/confirm" element={<ConfirmationPage />} /> 
          <Route path="/terms-and-condition" element={<Terms />} /> 

       

        </Routes>
        <Newsletter />
        <Footer />

        <ScrollToTop smooth component={<ImArrowUp color="black" size={17} />} />


        <GoToTop />




      </MyContextProvider>
    </BrowserRouter>


  )
}

export default App