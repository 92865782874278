import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import './ConfirmationPage.scss';
import { TiTick } from "react-icons/ti";
import MyContext from '../../../Common/Context/MyContext';
import LoginError from '../../../Common/LoginError/LoginError';

const PaymentConfirmation = () => {
  const [paymentStatus, setPaymentStatus] = useState('Verifying payment...');
  const [errorMessage, setErrorMessage] = useState(null);
  const{url,handleSubmit,token,Navigate} = useContext(MyContext)
  
  const orderId = new URLSearchParams(window.location.search).get('orderId'); // Assuming orderId is passed in query params

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        const { data } = await axios.post(`${url}/verify-payment`, { orderId });

        if (data.success) {
          setPaymentStatus('Confirmation Successful!');
          // Perform additional actions like redirecting to the order confirmation page or clearing the cart
          handleSubmit()
        } else {
          setPaymentStatus('Payment failed.');
          setErrorMessage(data.message || 'Failed to verify payment.');
          // You can also choose to redirect to a failure page or show an error message
        }
      } catch (error) {
        console.error('Error verifying payment:', error);
        setPaymentStatus('An error occurred while verifying the payment.');
        setErrorMessage(error.response?.data?.error || error.message);
      }
    };

    if (orderId) {
      verifyPayment();
    } else {
      setPaymentStatus('Order ID not found.');
    }
  }, [orderId, Navigate,url]);



return (
  <>
    {token ? (
      <div className="confirmation-page">
        <span><TiTick /></span>
        <div className='con-text'>
          <h1>{paymentStatus}</h1>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
         {paymentStatus==='Confirmation Successful!' &&
          <>
        <p>Thank you for your purchase. Your order has been received and is being processed.</p>
          <button onClick={()=>Navigate('/order')}>Show Order</button>
            </> 
         }
        </div>
      </div>
    ) : (
      <LoginError title="Please log in to view this page." />
    )}
  </>
);
}



export default PaymentConfirmation;
