

import { useContext} from "react";
import "./Category.scss";
import { useParams } from "react-router-dom";
import MainProduct from "../MainProduct/MainProduct";
import MyContext from "../../Common/Context/MyContext";




const Category = ({name}) => {


    const { title } = useParams()

const{data,sortOrder, setSortOrder} = useContext(MyContext)


 

    const sortedProducts = data.filter(item => item.product_category_route===title).map((Item) => {
        const sortedVariants = [...Item.product_container].sort((a, b) => {
            return sortOrder === 'asc' ? a.product_price - b.product_price : sortOrder === 'desc' ? b.product_price - a.product_price : sortOrder === 'rating' ? b.count - a.count:null

        });

        return { ...Item, product_container: sortedVariants };
    });

    const newdata = title ? sortedProducts:data

    return (

            <div className="category-page">

            <div className="category-heading">   {!name && title ? title : title && name ? 'Related Products' : 'Popular'}


            {!name && title && <select onChange={(e) => setSortOrder(e.target.value)} className="select-filter">
                  <option selected disabled>Filter</option>
                  <option value="asc">Low to High</option>
                  <option value="desc">High to Low</option>
                  <option value="rating">by rating</option>

              </select>}

          </div>

          <div className="category-products">

                {
                    newdata.map((outer) => {
                        return (

                            <>

                                    {
                                        outer
                                        .product_container
                                        .filter(inner => inner.product_route!==name)

                                            .map((inner) => {
                                                return (


                                                  <MainProduct   outer= {outer} inner={inner}/>
                                                )
                                            })
                                    }

                            </>

                        )
                    })
                }



            </div>
            </div>



        
    )
};

export default Category
