// StarRating.js

import React from 'react';
import './StarRating.scss';

const StarRating = () => {
 

  return (
    <div className="star-rating">
     
        <span className='filled' >
          ★ 
        </span>
     
    </div>
  );
};

export default StarRating;
