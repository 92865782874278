// ShippingForm.js

import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField
  , Button} from '@mui/material';
  import axios from 'axios';
import './Login.scss';
import IconButton from "@mui/material/IconButton";
import { AccountCircle, Email, Phone } from "@mui/icons-material";
import KeyIcon from '@mui/icons-material/Key';

import InputAdornment from "@mui/material/InputAdornment";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MyContext from '../../../Context/MyContext';




const Login = () => {

  


  const{setOpen,mainloader,url,setMainloader,showPassword, setShowPassword,showLogin,setMessage,closeModal4,LoginHide,RegisterHide,handleLogin} = useContext(MyContext)



  // for login
  const formik = useFormik({
    initialValues: {
      email: '',
      password:''

    },
    validationSchema: Yup.object({
      email: Yup
      .string('Enter your email')
      .matches(/^\S*$/, 'email cannot contain whitespace')
      .email('Enter a valid email')
      .required('Email is required'),
   
  password: Yup
      .string('Enter the password')
      .matches(/^\S*$/, 'email cannot contain whitespace')
      .min(8,'password atleast 8 charcter')
      .required('password is required'),
      
    
    
    }),
  

    onSubmit: async (values, { resetForm }) => {
        setMainloader(true);
        document.querySelector('body').style.overflow = "hidden";
    
        try {
            const {data} = await axios.post(`${url}/login`, values);
    
            
    
            if (data.success) {
                handleLogin(data);
                resetForm();
            } else {
                setMessage(data.error);
                setOpen(true);
            }
        } catch (error) {
            alert(error.response ? error.response.data.error : error.message);
        } finally {
            document.querySelector('body').style.overflow = "auto";
            setMainloader(false);
        }
    }
    
  });

 


// for register
  const formikRegister = useFormik({
    initialValues: {
      name:'',
      email: '',
      mobile:'',
      password:''

    },
    validationSchema: Yup.object({
    name: Yup
    .string("Enter your Name")
    .required("Name is required")
    .matches(/^([^0-9]*)$/, "Don't allow Numeric Value"),
    email: Yup
    .string('Enter your email')
    .matches(/^\S*$/, 'email cannot contain whitespace')
    .email('Enter a valid email')
    .required('Email is required'),
   
    mobile: Yup
    .string("Enter your Mobile.No")
    .required('Mobile number is required')
    .matches(/^\S*$/, 'mobile cannot contain whitespace')
    .matches(/^[0-9]{10}$/, "Mobile number is not valid"),
    password: Yup
    .string('Enter the password')
      .min(8,'password atleast 8 charcter')
      .matches(/^\S*$/, 'email cannot contain whitespace')
      .min(8, "Password must be at least 8 characters")
      .max(12, "Password must be at most 12 characters")
      .required('password is required'),
      
    
    
    }),

   

    
    onSubmit: async(values, { resetForm }) => {
      setMainloader(true);
      document.querySelector('body').style.overflow = "hidden";
      try {
          const {data} = await axios.post(`${url}/regsiser`, values);
  
        
  
          if (data.success) {
              setOpen(true);
              setMessage(data.message);
              resetForm();
              RegisterHide();
          } else {
              setOpen(true);
              setMessage(data.error);
          }
      } catch (error) {
          alert(error.response ? error.response.data.error : error.message);
      } finally {
          document.querySelector('body').style.overflow = "auto";
          setMainloader(false);
      }
  }
  
  
  })

  return (

    <>
    {showLogin &&

    <div className="login-main">
    <div className="opac-layer"> </div>
      <div className="login-content" id='login'>

        <div className="model-upper">

      <div className="model-heading">Sign In</div>
      { !mainloader && <div className="model-close" onClick={closeModal4}>&#x2715;</div>}
      </div>


     

      <form onSubmit={formik.handleSubmit} className='form-modal'>

     

      <TextField
     
      
      name="email"
      label="Email"
      placeholder="Email-Id"
      id="email"
   
      value={formik.values.email}
      onChange={formik.handleChange}
      error={formik.touched.email && Boolean(formik.errors.email)}
      helperText={formik.touched.email && formik.errors.email}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Email />
          </InputAdornment>
        ),
      }}
      variant="outlined"/>
      
      <TextField
     
        label="Password" placeholder="Password"
    
      name="password"
      value={formik.values.password}
      onChange={formik.handleChange}
      error={formik.touched.password && Boolean(formik.errors.password)}
      helperText={formik.touched.password && formik.errors.password} 
      id="outlined-adornment-password"
      type={showPassword ? 'text' : 'password'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            <KeyIcon/>
            </InputAdornment>
            ),
        endAdornment:(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() =>setShowPassword(!showPassword)}
              
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }} />

     

   

      <Button type="submit"  color="primary" className='btn'>
      {mainloader?'wait...':'login'}{ !mainloader && <ArrowRightAltIcon/>} &nbsp; {mainloader && <span className='login-loader'></span>}
      </Button>

     {!mainloader && <div className='register-link'>New User? &nbsp;<span onClick={LoginHide}>register</span></div>}
    </form>
      

    </div>

    <div className="login-content" id='register'>

    <div className="model-upper">

  <div className="model-heading">Sign Up</div>
  { !mainloader && <div className="model-close" onClick={closeModal4}>&#x2715;</div>}
  </div>


 

  <form onSubmit={formikRegister.handleSubmit} className='form-modal'>

 

 
  <TextField
  
  size="small"
  label="name"
  placeholder="name"
  id="name"
  name="name"
  value={formikRegister.values.name}
  onChange={formikRegister.handleChange}
  error={formikRegister.touched.name && Boolean(formikRegister.errors.name)}
  helperText={formikRegister.touched.name && formikRegister.errors.name}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircle />
      </InputAdornment>
    ),
  }}
 
/>

 <TextField
 
    
      size="small"
      name="email"
      label="Email"
      placeholder="emailid"
      id="email"
      value={formikRegister.values.email}
      onChange={formikRegister.handleChange}
      error={formikRegister.touched.email && Boolean(formikRegister.errors.email)}
      helperText={formikRegister.touched.email && formikRegister.errors.email}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Email />
          </InputAdornment>
        ),
      }}
      variant="outlined"/>


            <TextField
            
             size="small"
              label="Mobile.No"
              placeholder="mobile number"
              id="mobile"
              name="mobile"
              value={formikRegister.values.mobile}
              onChange={formikRegister.handleChange}
              error={formikRegister.touched.mobile && Boolean(formikRegister.errors.mobile)}
              helperText={formikRegister.touched.mobile && formikRegister.errors.mobile}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                ),
              }}
              
            />

  <TextField
    label="Password" placeholder="Password"
    size="small"
      name="password"
      value={formikRegister.values.password}
      onChange={formikRegister.handleChange}
      error={formikRegister.touched.password && Boolean(formikRegister.errors.password)}
      helperText={formikRegister.touched.password && formikRegister.errors.password} 
      id="outlined-adornment-password"
      type={showPassword ? 'text' : 'password'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            <KeyIcon/>
            </InputAdornment>
            ),
        endAdornment:(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() =>setShowPassword(!showPassword)}
             edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }} />

  
 


 




 
 

 



  <Button type="submit" variant="contained" color="primary" className='btn'>
   {mainloader?'wait...':'register'} { !mainloader && <ArrowRightAltIcon/>} &nbsp; {mainloader && <span className='login-loader'></span>}
  </Button>

{!mainloader &&  <div className='register-link'>Already Account? &nbsp;<span onClick={RegisterHide}>login</span></div>}
</form>
  

</div>
    </div>
    }

    </>

  );
};

export default Login;






  
    
    



