import "./Cart.scss";
import {MdClose} from 'react-icons/md'
import { useContext } from "react";
import CartItem from "./CartItem/CartItem";
import MyContext from "../../Context/MyContext";
const Cart = () => {

    

    const {showCart,closeModal2,TotalValue,cartItems} = useContext(MyContext)
    return (
        <>
     { showCart  &&
        <div className="cart-panel">
        <div className="opac-layer" onClick={closeModal2}></div>
        <div className="cart-content">
            <div className="cart-header">
                <span className="heading">Shopping Cart <small>({cartItems?.length || 0} Item Total)</small></span>
                <span className="close-btn" onClick={closeModal2}>
                <MdClose/>
                <span className="text">close</span>
                </span>

            </div>

          

           <>
           <CartItem/>
           <div className="cart-footer">
            <div className="subtotal">
                <span className="text" >Subtotal</span>
                <span className="text total">&#8377; 

               {TotalValue}
                
              
                </span>
              
            </div>
            <div className="button">

            {
                cartItems && cartItems.length > 0 ?
                  <button className="checkout-cta"  onClick={() => window.location.href='/checkout'}>Checkout</button> :
                  <button className="checkout-cta" disabled style={{ opacity: "0.7",cursor:"no-drop" }}>Checkout</button>
              }
              
                   
                    
                
               
            </div>
           </div>
           </>
        </div>


    </div>
            }
    </>
            )
};

export default Cart;
