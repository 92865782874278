import "./SingleProduct.scss";
import {
  FaFacebookF,
  FaCartPlus,
  FaCopy,
  FaWhatsapp,
  FaTwitter,
} from 'react-icons/fa';
import { useParams } from "react-router-dom";
import SingleImage from "./SingleImage/SingleImage";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useContext} from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import StarRating from "../StarRating/StarRating";
import MyContext from "../../Common/Context/MyContext";
import Category from "../Category/Category";

const SingleProduct = () => {
 
  

  const {

    loading,
    handleSize,
    handleCheckout,
    selectSize,
   size,
   handlewish,

    openModal2,
    cursorPosition,
    isProductInWish,
    isProductInCart,
    data,
    saveimg,
    removeProductFromWish,

    formatReviewCount,
 
    overshow
  } = useContext(MyContext);



  const { title, name } = useParams();

  const fullUrl = window.location.href

  return (

      <div className="layout">
        {data.filter(Item => Item.product_category_route=== title).map((outer) => {
          return (
            <>
              {outer.product_container.filter(Item => Item.product_route === name)
                .map((inner) => {
                return (
                  <div className="single-product-page" key={inner.id}>
                    <div className="left">
                      <SingleImage img={inner.product_img} sideimg = {inner.side_img} />
                    
                        
                      
                        
                        {!isProductInWish(outer.id, inner.id) ? (
                          <span className="heart" onClick={()=>handlewish(outer.id,inner.id,inner.product_img,inner.product_name,inner.product_price)}><AiOutlineHeart /></span>
                        ) : (
                          <span className="heart" onClick={() => removeProductFromWish(outer.id, inner.id)}>
                            <AiFillHeart />
                          </span>
                        )}
                      
                    </div>
                    <div className="right">
                   { overshow &&
                    <div className="overlay-big"
                    style={{ 
                      backgroundPosition: `-${cursorPosition.x * 2}px -${cursorPosition.y * 2}px`,
                      backgroundImage: `url(${saveimg})`,
                      backgroundSize: '150%' ,
                   
                    
                      }}>
                      </div>}
                      <span className="name">{inner.product_name}</span>
                      <span className="price">&#8377;{inner.product_price} &nbsp; <del style={{ color: "rgba(0,0,0,0.5)" }}> &#8377;{inner.product_price_deleted} </del></span>
                      <div className="rating">
                        <StarRating />{inner.rating} ({formatReviewCount(inner.count)})
                      </div>
                      <span className="desc"><div>{inner.product_description}</div></span>

                      <div class="size">
                      {
                        inner.size_main.map((a) =>{
                          return(
                            <span style={{backgroundColor:a.size===size && 'black',color:a.size===size && 'white'}} 
                            onClick={() => handleSize(a.size)}>
                            {a.size}
                            </span>
                          )
                        })
               
                 
                      }
                      
                </div>
              { selectSize && <p>** please select size</p>}
                      <div className="cart-buttons">
                        {isProductInCart(outer.id,inner.id) ?
                          <button className="add-to-cart-button" onClick={openModal2}> <FaCartPlus size={20} /> &nbsp; Go To Cart</button> :
                          <button className="add-to-cart-button" onClick={() => handleCheckout(outer.id,inner.id,inner.product_img,inner.product_name,inner.product_price)} disabled={loading}>  {loading ? 'Adding to Cart...' : <><FaCartPlus size={20}/> &nbsp; Add To Cart</>}</button>
                        }
                        {/*<button className="add-to-cart-button" onClick={() => handleBuy(inner.product_price)}> &nbsp; Buy Now</button>*/}
                      </div>
                      <span className="divider" />
                      <div className="info-item">
                        <span className="text-bold">Category:
                          <span className="normal-text">&nbsp;{outer.product_category}</span>
                        </span>
                        <span className="text-bold">Share:
                          <span className="Social-icons" style={{ display: "flex" }}>
                            <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(fullUrl)}`} target="_blank" rel="noopener noreferrer">
                              <FaFacebookF title="facebook" />
                            </a>
                            <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(fullUrl)}`} target="_blank" rel="noopener noreferrer">
                              <FaTwitter title="instergram" />
                            </a>
                            <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(fullUrl)}`} target="_blank" rel="noopener noreferrer">
                              <FaWhatsapp title="whatsapp" />
                            </a>
                            <CopyToClipboard text={fullUrl}>
                              <FaCopy title="copy" />
                            </CopyToClipboard>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          )
        })}
      

        <Category name={name}/>
      </div>

  );
};

export default SingleProduct;
