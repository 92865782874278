import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import MyContext from './MyContext'

const MyContextProvider = ({children}) => {

// loaader start
  const[mainloader,setMainloader] = useState(false)
// loader end


// message for alert start
const [message,setMessage] = useState('')
// message for alert end


//  open alert start
const [open,setOpen] = useState(false)
//  open alert end


//location state start
const location = useLocation();
//location state end

//navigation state start
const Navigate = useNavigate();
//navigation state end



// header scrolling effect (for header) start
const [scrolled, setScrolled] = useState(false)
const HandleScroll = () => {
  const offset = window.scrollY
  if (offset > 200) {
      setScrolled(true);

  } else {
      setScrolled(false);
  }
}
useEffect(() => {
  window.addEventListener("scroll", HandleScroll)
}, [])
// header scrolling effect (for header) end


// for insta open(newslater) start
const handleInsta = () => {

  window.open('https://www.instagram.com/vhx_view')
}
// for insta open(newslater) end






// for wishlist open close state start
const [showWishList, setShowWishList] = useState(false)
// for wishlist open close state end

// for wishlist open start
const openModal = () => {
  setShowWishList(true);
  document.querySelector('body').style.overflow="hidden"
};
// for wishlist open end

// for wishlist close start
const closeModal = () => {
  setShowWishList(false);
document.querySelector('body').style.overflow="auto"
};
// for wishlist close end


// for search open close state start
const [showSearch, setShowSearch] = useState(false)
// for search open close state end

// for search open start
const openModal1 = () => {
  setShowSearch(true);
  document.querySelector('body').style.overflow="hidden"
};
// for search open end


// for search close start
const closeModal1 = () => {
  setShowSearch(false);
  setValue('')
document.querySelector('body').style.overflow="auto"
};
// for search close end


// for cart open close state start
const [showCart, setShowCart] = useState(false)
// for cart open close state end

// for cart open start
const openModal2 = () => {
  setShowCart(true);
 document.querySelector('body').style.overflow="hidden"
};
// for cart open end


// for cart close start
const closeModal2 = () => {
  setShowCart(false); 
  document.querySelector('body').style.overflow="auto"
};
// for cart close end


// for login open close state start
const [showLogin, setShowLogin] = useState(false)
// for login open close  state end


// for login password show start
const [showPassword, setShowPassword] = useState(false);
// for login password end start


// for login Modal Open start
const openModal4 = () => {
  setShowLogin(true);
  document.querySelector('body').style.overflow="hidden"
};
// for login Modal Open end


// for login Modal Close start
const closeModal4 = () => {
  setShowLogin(false);
 document.querySelector('body').style.overflow="auto"
};
// for login Modal Close end


// for login hide start
const LoginHide = () =>{
document.getElementById('login').style.display='none'
document.getElementById('register').style.display='block'
// for register close end
 
  
}
// for login hide end


// for register hide start
const RegisterHide = () =>{
document.getElementById('login').style.display='block'
document.getElementById('register').style.display='none'

}
//for register hide end


// for api state start
const [data, setData] = useState([])
  // for api state end
  

   
// fetch data fron api start
const[apiloader,setApiloader]=useState(true)

const url = process.env.REACT_APP_API_URL || 'http://localhost:3034'

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}/api`);
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setApiloader(false);
    }
  };

  fetchData();
}, [url]);

  // fetch data fron api end
  

  // for search input start
 const [value, setValue] = useState('');
 // for search input end
 

//  for navigate in the product listing start
const handleSearch = (category) =>{
  Navigate(`/category/${category}`)
  setValue('')
  closeModal1()
}
//  for navigate in the product listing end



// for filter sort order state start
const [sortOrder, setSortOrder] = useState("");
// for filter sort order state end


// for stop autoplay hover in product start
const [n,setN] = useState('')
// for stop autoplay hover in product end

// for review count start
const formatReviewCount = (count) => {
  if (count && count >= 1000) {
    return `${(count / 1000).toFixed(1)}k`;
  }
  return count || null;
};
// for review count end

// for size select error state start
const [selectSize,setSelectSize] = useState(false)
// for size select error state over

// for size save  state start
const[size,setSize] = useState('')
// for size save  state over


// for size button click start
const handleSize = (size) =>{
  setSize(size)
  setSelectSize(false)
} 
// for size button click end


// big zoom img in single product start
const[overshow,setOvershow] = useState(false)
// big zoom img in single product end
  
// for image movement start
const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
// for image movement end

// for image movement save start
const [saveimg, setSaveimg] = useState('');
// for image movement save end


// mouse over for single product start
const handlebigover = () =>{
  setSaveimg(document.getElementById('change').src)
  setOvershow(true)
}
// mouse over for single product end


// for image movement start
const handleMouseMove = (event) => {
  const image = document.getElementById('change');
  const rect = image.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;


  setCursorPosition({ x: x, y: y });
  };
// for image movement end


// for saving token state start
const[token,setToken] = useState(()=>{
  const storetoken=sessionStorage.getItem('token')
    return storetoken?storetoken:''
  
})


// for saving token state start




// handle login start
const handleLogin = (data) => {
  sessionStorage.setItem('token', data.data);
  sessionStorage.setItem('userdata', JSON.stringify(data.accountInfo));
  sessionStorage.setItem('cart', JSON.stringify(data.cartInfo));
  sessionStorage.setItem('wish', JSON.stringify(data.wishInfo));
  sessionStorage.setItem('shipping', JSON.stringify(data.shippingInfo));
  sessionStorage.setItem('order', JSON.stringify(data.orderInfo));
  setWish(data.wishInfo)
  setOrder(data.orderInfo)
  setUserdata(data.accountInfo)
  setToken(data.data);
  setOpen(true)
  setMessage(data.message)
  setCartItems(data.cartInfo)
  setShipping(data.shippingInfo)
  closeModal4() 
};
// handle login end


// handle logout start
const handleLogout = () => {
 
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userdata');
    sessionStorage.removeItem('cart');
    sessionStorage.removeItem('wish');
    sessionStorage.removeItem('shipping');
    sessionStorage.removeItem('order');
    setToken('');
    setUserdata(null)
    setCartItems([]);
    setWish([])
    setOrder([])
    setShipping(null)
    window.location.reload()
    closelModal()
  }



// handle logout end


// logout modalopen state starts 
const [lmodal, setLmodal] = useState(false);


const openlModal = () => {
   document.querySelector('body').style.overflow="hidden"
  setLmodal(true);
 
};

const closelModal = () => {
  setLmodal(false);
  document.querySelector('body').style.overflow="auto"
};

// logout modalopen  state ends




// for account data save state start
const [userdata, setUserdata] = useState(()=>{
  const storeUser = sessionStorage.getItem('userdata')
  return storeUser?JSON.parse(storeUser):null
});


const[edit,setEdit]=useState(false)
// for account data save state end



// for save caritems state start
  const [cartItems,setCartItems] = useState(() => {
    const savedCart = sessionStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });
// for save caritems state end




// loading for cart button singleproduct state start
const[loading,setLoading] = useState(false)
// add to cart button singleproduct state process start


// for add to cart button process start
const handleCheckout = async (categoryid,productid,productimg,productname,productprice) => {

  try {
   
   
    if (!token) {
      setOpen(true)
      setMessage('please login first')
   setTimeout(() => {
    openModal4()
   }, 2500);
     return;
    }

    if (!size) {
      setSelectSize(true);
      return;
    }
    setLoading(true);
    const { data } = await axios.post(`${url}/add-to-cart`, {
      categoryid, 
      productid, 
      size, 
      productimg, 
      productname, 
      productprice
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })



    if(data.success){
   setOpen(true)
   setMessage(data.message)
   setCartItems(data.cartInfo)
   sessionStorage.setItem('cart', JSON.stringify(data.cartInfo));
  openModal2()
   setSize('')
    }else{
      setOpen(true)
      setMessage(data.error)
    }
  } catch (error) {
    console.error('Error during checkout:', error.response?.data?.error || error.message);
  } finally {
    setLoading(false);
  }
};
//for add to cart button process end














// get cartdata from api matching with database start
const cartProductData =cartItems &&  cartItems.map(cart =>{
  const category = data.find(category => category.id === cart.categoryid);
  
  if (category && category.product_container) {
    const product = category.product_container.find(product => product.id === cart.productid);
    
    if (product) {
      return { ...product, ...cart };
    }
  }

  return null;
})
// get cartdata from api matching with database end


// total value start
const TotalValue =cartItems && cartItems.reduce((acc, item) => acc + (item.productprice * item.quantity), 0);





// show go to cart start
const isProductInCart = (categoryid,productid) => {
  if (cartItems) {
      return cartItems.find(item =>item.categoryid===categoryid && item.productid === productid && item.size===size);
  }
  return false;
};

// show go to cart end



// remove product from cart start

const removeProductFromCart = async (categoryid,productid,size) => {
  try {

setMainloader(true)
const { data } = await axios.post(`${url}/remove-from-cart`, 
  { categoryid, productid, size }, 
  {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }
);
    if(data.success){
setOpen(true)
      setMessage(data.message)
      setCartItems(data.cartInfo)
      sessionStorage.setItem('cart', JSON.stringify(data.cartInfo));
    }else{
      setOpen(true)
      setMessage(data.error)
     
    }
    
  } catch (error) {
    console.error('Error removing from cart:', error.response?.data?.error || error.message);
    setMessage('An error occurred. Please try again.');
    setOpen(true);
  } finally {
    setMainloader(false);
  }

};
// remove product from cart end


// for increase quantity in cart start
const handleIncreaseQuantity = async (categoryid,productid,size) => {
 
  try {
  
setMainloader(true)

const { data } = await axios.post(
  `${url}/increase-quantity`,
  { categoryid, productid, size },
  {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }
);

    if(data.success){
      setCartItems(data.cartInfo)
      sessionStorage.setItem('cart', JSON.stringify(data.cartInfo));
     
  } else {
   setOpen(true)
   setMessage(data.error)
  }

  
  } catch (error) {
    console.error('Error adding quantity in cart:', error.response?.data?.error || error.message);
    setMessage('An error occurred. Please try again.');
    setOpen(true);
  }
  finally{
    setMainloader(false)
  }
};

// for increase quantity in cart end


// for increase quantity in cart start
const handleDecreaseQuantity = async (categoryid,productid,size) => {
 
  try {
  
setMainloader(true)

const { data } = await axios.post(
  `${url}/decrease-quantity`,
  { categoryid, productid, size },
  {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }
);
    if(data.success){

      setCartItems(data.cartInfo)
      sessionStorage.setItem('cart', JSON.stringify(data.cartInfo));

  } else {
   setOpen(true)
   setMessage(data.error)
  }

  
  }  catch (error) {
    console.error('Error deleting quantity in cart:', error.response?.data?.error || error.message);
    setMessage('An error occurred. Please try again.');
    setOpen(true);
  
   
  }
  finally{
    setMainloader(false)
  }
};

// for increase quantity in cart end



// for wish list  start
const [wish, setWish] = useState(() => {
  const savedwish = sessionStorage.getItem('wish');
  return savedwish ? JSON.parse(savedwish) : [];
});
// for wish list  end


// FOR ADD TO Wish PROCESS START
const handlewish = async (categoryid,productid,productimg,productname,productprice) => {

  try {
   
   
    if (!token) {
      setOpen(true)
      setMessage('please login first')
   setTimeout(() => {
    openModal4()
   }, 2500);
     return;
    }

    setMainloader(true);
    const {data} = await axios.post(`${url}/add-to-wish`, 
      {categoryid,productid,productimg,productname,productprice},
      {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    
    );


    if(data.success){
   setOpen(true)
   setMessage(data.message)
   setWish(data.wishInfo)
   sessionStorage.setItem('wish', JSON.stringify(data.wishInfo));
    }else{
      setOpen(true)
      setMessage(data.error)
    }
  }  catch (error) {
    setMessage(error.response?.data?.error || error.message);
    setOpen(true);
   
  } finally {
    setMainloader(false);
  }
};

 // FOR ADD TO Wish PROCESS end



   // remove product from cart start

const removeProductFromWish = async (categoryid,productid) => {
  try {

    setMainloader(true)
    const {data} = await axios.post(`${url}/remove-from-wish`,
      { categoryid,productid },
       {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
     
    });

 
    if(data.success){
      setMessage(data.message)
      sessionStorage.setItem('wish', JSON.stringify(data.wishInfo));
      setWish(data.wishInfo)
      setOpen(true)
    
    }else{
      setMessage(data.error)
      setOpen(true)
     
    }
    
    
  }  catch (error) {
    setMessage(error.response?.data?.error || error.message);
    setOpen(true);
   
  }finally{
    setMainloader(false)
  }
}
// remove product from cart end


  // show go to wish start
  const isProductInWish = (categoryid,productid) => {
    if (wish) {
        return wish.find(item =>item.categoryid===categoryid && item.productid === productid );
    }
    return false;
  };
  
  // show go to wish end


  
 
// for shipping data save state start
const [shipping, setShipping] = useState(()=>{
  const storedShipping = sessionStorage.getItem('shipping');
  return storedShipping? JSON.parse(storedShipping):null
})


// for shipping data save state end



 



   
  
  

// for payment option start\


const  generateCaptcha = () =>{
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}
    const [captcha, setCaptcha] = useState(generateCaptcha());
    const [enter, setEnter] = useState('');
    const [error, setError] = useState(false);

  
  
    const handleRefresh = () => {
      setCaptcha(generateCaptcha());
      setEnter('')
      setError(false)
    };


    // for order list  start
const [order, setOrder] = useState(() => {
  const savedorder = sessionStorage.getItem('order');
  return savedorder ? JSON.parse(savedorder) : [];
});
// for order list  end
  
const handleSubmit = async () => {
 

    try {
      const { data } = await axios.post(`${url}/add-to-order`, 
        { orderDate: new Date() },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (data.success) {
        setMessage(data.message);
        sessionStorage.setItem('cart', JSON.stringify(data.cartInfo));
        sessionStorage.setItem('order', JSON.stringify(data.orderInfo));
        setCartItems(data.cartInfo);
        setOrder(data.orderInfo);
        setOpen(true);
      } else {
        setMessage(data.error);
        setOpen(true);
      }
    } catch (error) {
      setMessage(error.response?.data?.error || error.message);
      setOpen(true);
    } finally {
      setMainloader(false);
    }

};

    

    // for payment option end
    
    const handleNavigate = (cid, pid) => {
      const category = data.find(item => item.id === cid);
      
      if (!category) {
        console.error('Category not found');
        return;
      }
    
      const product = category.product_container.find(prod => prod.id === pid);
      
      if (!product) {
        console.error('Product not found');
        return;
      }
    
    
      const product_category_route = category.product_category_route;  
      const product_route = product.product_route;            
    
      window.location.href = `/category/${product_category_route}/${product_route}`;
    };
    

  return (
    <MyContext.Provider value={{url,handleNavigate,handleSubmit,order, setOrder,handleRefresh,error, setError,enter, setEnter,captcha, setCaptcha,shipping, setShipping,wish,handlewish,removeProductFromWish,isProductInWish, setWish,openlModal,closelModal,lmodal,edit,setEdit,apiloader,cartProductData,handleMouseMove,saveimg,handlebigover,cursorPosition, setCursorPosition,overshow,setOvershow,n,setN,sortOrder, setSortOrder,handleSearch,value,setValue,showPassword,setShowPassword,handleInsta,open,setOpen,Navigate,location,scrolled,mainloader,setMainloader,handleSize,size,setSize,selectSize,setSelectSize,message,setMessage,loading,handleCheckout,cartItems,setCartItems,token,handleLogin,userdata,setUserdata,handleLogout,LoginHide,RegisterHide,openModal4,closeModal4,showLogin,setShowLogin,isProductInCart,data,formatReviewCount,showCart,setShowCart,removeProductFromCart,handleIncreaseQuantity,handleDecreaseQuantity,TotalValue,showWishList,setShowWishList,openModal,closeModal,openModal1,closeModal1,showSearch,closeModal2,openModal2}} >
      {children}
    </MyContext.Provider>
  )
}

export default MyContextProvider