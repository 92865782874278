import "./Footer.scss";
import { FaLocationArrow,FaMobileAlt,FaEnvelope } from "react-icons/fa";
import Payment from "../../assets/payments.png"
import { useContext } from "react";
import MyContext from "../Context/MyContext";




const Footer = () => {

    const{Navigate,data} = useContext(MyContext)

    
    return (
        <div className="footer">
    <div className="footer-content">
        <div className="col">
            <div className="title">About</div>
            <div className="text">
            Stay attuned to men's fashion trends and ensure that VHX offerings align with contemporary styles. Highlight key fashion elements and styles that resonate with your male audience
            </div>
        </div>


        <div className="col">
        <div className="title">Contact</div>

        <div className="c-item">
            <FaLocationArrow/>
            <div className="text">202,kunal complex
             nizampura,vadodara-390002</div>
        </div>

        <div className="c-item">
            <FaMobileAlt/>
            <div className="text">+931 605 1170</div>
        </div>
       

        <div className="c-item">
            <FaEnvelope/>
            <div className="text">veer@vhxview.com</div>
        </div>
        </div>

        <div className="col">
        <div className="title">Categories</div>

        {data.map((item) =>{
            return(
                <div className="text" onClick={() => Navigate(`/category/${item.product_category_route}`)}>{item.product_category}</div>
            )
        })
       
        }
        
       
       
        
        
        </div>

        <div className="col">
        <div className="title">Pages</div>
        <div className="text" onClick={() => Navigate('/')}>Home</div>
        
        <div className="text" onClick={() => Navigate('/contact')}>Contact Us</div>
        <div className="text" onClick={() => Navigate('/terms-and-condition')}>Terms and Condition</div>
       
        
        </div>
        
    </div>


        <div className="bottom-bar-content">
            <div className="text">
                VHX View 2024.PREMIUM E-COMERCE SOLUTION.
            </div>
            <img src={Payment} alt=""/>
        </div>
   


    </div>
    )

};

export default Footer;
