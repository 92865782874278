import React, { useContext} from 'react';
import './LogoutModal.scss';
import MyContext from '../../../../Context/MyContext';
import { FiDelete } from "react-icons/fi";

const LogoutModal = () => {
  const { closelModal, lmodal,handleLogout } = useContext(MyContext)

 

 

  return (
    <>
      {lmodal && 

        <>

        <div className='over_modal' onClick={closelModal}></div>
        <div className='modal_main' >
   
            <span onClick={closelModal}><FiDelete size={20}/></span> 
            <p>Are you sure you want to log out?</p>

            <div className='btn'>
            <button onClick={handleLogout}>Yes</button>
            <button onClick={closelModal}>No</button>
            </div>
  
        </div>

        </>
      }
    </>
  );
}

export default LogoutModal;
