import React, { useContext} from "react";
import { MdClose } from "react-icons/md";
import "./Search.scss";
import MyContext from "../../Context/MyContext";



const Search = () => {


   const {value, setValue,showSearch,data,closeModal1,handleSearch} = useContext(MyContext)


  
    

  
    return (
        <>

        {showSearch &&
        <div className="search-modal">

            <div className="form-field">
                <input
                    autoFocus
                    type="text"
                    placeholder="Search for products..."
                    value={value}
                    onChange={(e) =>setValue(e.target.value)}
                  
                   
                />
                <MdClose
                    className="close-btn"
                    onClick={closeModal1}
                />
            </div>

            <div className="search-result-content">
              
                <div className="search-results">

{
    data



    .filter((Item) => {
        const userValue = value.toLowerCase()
        const jsonValue = Item.product_category.toLowerCase()

        return(
        userValue &&
            jsonValue.startsWith(userValue)
           
        )
    })

  
    
    .map((Item) => {
        return(
            <div className="search-result-item" >
                            
                            
            <span className="name"   onClick={() =>handleSearch(Item.product_category_route) }>
         {Item.product_category}
            </span>
           
            
       
    </div>
        )
    })
}
     
                
                       
                  
                </div>
            </div>
        </div>

}
</>
    );
};

export default Search;