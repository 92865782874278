import React, {useContext} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {InputAdornment, TextField} from '@mui/material';
import './Contact.scss';
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';
import {  EmailOutlined, MessageOutlined, Person2Outlined, PhoneCallbackOutlined } from '@mui/icons-material';
import MyContext from '../../Common/Context/MyContext';


const Contact = () => {



const {setMessage,url,setOpen,mainloader,setMainloader} = useContext(MyContext)
 


  const formik = useFormik({
    initialValues: {
      name: '',
      mobile: '' ,
      email: '',
      message: '',
      
    },
    validationSchema: Yup.object({
      name: Yup.string().matches(/^[a-zA-Z]+(?:\s[a-zA-Z]+){0,2}$/, '*Only alphabets are allowed in the name, and maximum 2 spaces are allowed').required('*Name is required'),
      mobile: Yup.string().matches(/^[0-9]{10}$/, '*Invalid mobile number').required('*Mobile number is required'),
      email: Yup.string().email('*Invalid email address').required('*Email is required'),
      message: Yup.string().required('*Message is required'),
      
    }),
   onSubmit: async (values, { resetForm }) => {
    setMainloader(true);
    document.querySelector('body').style.overflow = "hidden";

    try {
        const { data } = await axios.post(`${url}/contact`, values);

        if (data.success) {
            setOpen(true);
            setMessage(data.message);
            resetForm();
        } else {
            setOpen(true);
            setMessage(data.error);
        }
    } catch (error) {
        alert(error.response ? error.response.data.error : error.message);
    } finally {
        document.querySelector('body').style.overflow = "auto";
        setMainloader(false);
    }
}
  });




  return (

    
   
  
    <div className='contact'>
     

    

       
          <h2>Write Your Issue</h2>
          <form onSubmit={formik.handleSubmit}>
          

            <TextField
            type="text"
            id="name"
            name="name"
            placeholder='Name'
            size="small"
            value={formik.values.name}
            onChange={formik.handleChange}
error={formik.touched.name && Boolean(formik.errors.name)}
helperText={formik.touched.name && formik.errors.name} 
label="Name" 
InputProps={{
  startAdornment: (
    <InputAdornment position="start">
      <Person2Outlined fontSize="small"/>
    </InputAdornment>
  ),
}}
variant="outlined" />


<TextField
type="text"
id="mobile"
name="mobile"
placeholder='mobile'
size="small"
value={formik.values.mobile}
onChange={formik.handleChange}
error={formik.touched.mobile && Boolean(formik.errors.mobile)}
helperText={formik.touched.mobile && formik.errors.mobile} 
label="mobile" 
InputProps={{
  startAdornment: (
    <InputAdornment position="start">
      <PhoneCallbackOutlined fontSize="small"/>
    </InputAdornment>
  ),
}}
variant="outlined" />

<TextField
type="text"
id="email"
name="email"
placeholder='email'
size="small"
value={formik.values.email}
onChange={formik.handleChange}
error={formik.touched.email && Boolean(formik.errors.email)}
helperText={formik.touched.email && formik.errors.email} 
label="email" 
InputProps={{
  startAdornment: (
    <InputAdornment position="start">
      <EmailOutlined fontSize="small"/>
    </InputAdornment>
  ),
}}
variant="outlined" />

           

<TextField
type="text"
id="message"
InputProps={{
  startAdornment: (
    <InputAdornment position="start">
      <MessageOutlined fontSize="small"/>
    </InputAdornment>
  ),
}}
name="message"
placeholder='message'
multiline
size="small"
rows={4}
value={formik.values.message}
onChange={formik.handleChange}
error={formik.touched.message && Boolean(formik.errors.message)}
helperText={formik.touched.message && formik.errors.message} 
label="message" 
variant="outlined" />

            <button className='btn' endIcon={<SendIcon fontSize="small"/>} variant="contained"  type="submit" >
            
            {mainloader ?'wait...':'send message'}</button>
          
          </form>
       
      </div>
     
 


   
   




    


  );
};

export default Contact;