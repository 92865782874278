import "./Header.scss";
import { useContext } from "react";
import { AiOutlineHeart, AiOutlineSearch,AiFillHeart } from "react-icons/ai";
import { CgShoppingCart } from "react-icons/cg"
import log from "../../assets/logo.png"
import MyContext from "../Context/MyContext";
import { RiArrowDropDownFill } from "react-icons/ri";



const Header = () => {

   

    const {userdata,token,Navigate,scrolled,wish,cartItems,openModal,openModal1,openModal2,openModal4} = useContext(MyContext)



return (

       
       
            <header className={`main-header ${scrolled ? 'sticky-header' : ""}`}>
                <div className="header-content">
                    
                    <div className="left" onClick={() => Navigate('/')}>
                    <img src={log} alt=""/>
                    </div>
                    <div className="center">The view Of Fitness</div>
                    <div className="right">

                    
                    {token?
                      
                  <div className="accounti" onClick={() =>window.location.href='/account'}><span>Hi,{userdata &&  userdata.name}</span>
                  <span>account <RiArrowDropDownFill /></span></div>:
                    <div className="login" onClick={openModal4}>login</div>
                    }

                  
            
           
                        <AiOutlineSearch onClick={openModal1} />
                      { 
                        wish &&wish.length >0 ?
                        <AiFillHeart onClick={openModal}/>:
                         <AiOutlineHeart onClick={openModal} />
                       
                        }
                       
                        <span className="cart-icon " onClick={openModal2}>
                            <CgShoppingCart />
                            <span>{cartItems?.length || 0}</span>
                        </span>
                       
                    </div>
                </div>
            </header>

       
    )


};

export default Header;
