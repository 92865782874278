import React, { useContext, useEffect, useState } from 'react';
import "./Order.scss";

import { AiOutlineHeart } from "react-icons/ai";
import MyContext from '../../../../Context/MyContext';
import axios from 'axios';

const Order = () => {
  const [loading, setLoading] = useState(true);
  const { order, setOrder,url,token,handleNavigate } = useContext(MyContext);

  useEffect(() => {
  
    const fetchOrderItems = async () => {

      if (!token) {
        setLoading(false);
        return;
      }
      try {
        const {data} = await axios.get(`${url}/order`, { 
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
     
        setOrder(data.orderInfo);
        sessionStorage.setItem('order', JSON.stringify(data.orderInfo));
      } catch (error) {
        console.error('Failed to fetch order details:', error.response?.data?.error || error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchOrderItems();
  }, [setOrder, token,url]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className='order-container'>
      <div className="order-content">

      {order && order.length > 0 && <h1>order Details -Total Items {order.length}</h1>}
        <div className='order-center'>
          {
            order && order.length > 0 ?
              order
              .sort((a,b)=>b._id.localeCompare(a._id))
              .map((item) => (
                <div className="order-item" key={item._id}>
          
                <img src={item.productimg} alt={item.productname} 
                 className="order-item-img"
                 onClick={()=>handleNavigate(item.categoryid,item.productid)} />
                <div className="order-item-details">
                <p>Order Date: {item.orderDate.slice(0,10)}</p>
                  <h3>{item.productname}</h3>
                  <p>Price: ${item.productprice}</p>
                  <p>Size: {item.size}</p>
                  <p>Quantity: {item.quantity}</p>
                  <p>Order ID: {item._id.slice(-4)}</p>
                </div>
              </div>
              )) :
              <div className='order-empty'>
                <span className='order-empty-icon'><AiOutlineHeart /></span>
                <span className='order-empty-text'>You don't have any products in the wishlist yet.
                  <br />
                  You will find a lot of interesting products on our "Shop" page.
                </span>
                <button className='order-btn'>Continue Shopping</button>
              </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Order;
