//Loader.jsx

import React, { useContext } from 'react';
import './Loader.scss';
import MyContext from '../Context/MyContext';


const Loader = () => {

  const {mainloader,apiloader} = useContext(MyContext)


 

  return (

    <>
    {(mainloader||apiloader)  &&
    <div className="loader-containern">
      
      <div className="loader-ring"></div>
    </div>
    }
    </>
  );
};

export default Loader;