import React, { useContext } from 'react'
import './Account.scss'
import MyContext from '../../../Context/MyContext';
import LoginError from '../../../LoginError/LoginError';

const Account = () => {

  const{token,openlModal} = useContext(MyContext)
  return (
<>
    {token?
    <div className='account'>
    <div className='account-division' onClick={() =>window.location.href='/account-details'}>
   Account Information
    </div>

    <div className='account-division' onClick={() =>window.location.href='/order'}>
   order-details
    </div>


   
    <div className='account-division' onClick={openlModal}>
    Logout
    </div>

    <div className='account-division' onClick={() =>window.location.href='/shipping-info'}>
    shipping Address
    </div>

    <div className='account-division' >
    Our Studio
    <div className='overlay'></div>
    </div>
    </div>:
    <LoginError title="Account Page"/>
    }
    </>
  )
}

export default Account