import React, { useContext} from 'react'
import "./MainProduct.scss"
import StarRating from '../StarRating/StarRating'
import { AiFillHeart, AiOutlineHeart} from 'react-icons/ai'
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import MyContext from '../../Common/Context/MyContext';

const MainProduct = ({outer,inner}) => {
   

    const {formatReviewCount,isProductInWish,handlewish,removeProductFromWish} = useContext(MyContext)

    

    // const isMobileView = () => window.innerWidth <= 768;

// const auto = n===inner.product_name?true:false && !isMobileView

   
  return (

   

   

   
    // <div className="category-container" onMouseEnter={() => setN(inner.product_name)}
    // onMouseLeave={() => setN('')}>

    <div className="category-container">

    <div className="product-page">
        

    {/*<Carousel
                    showThumbs={false}
                    showArrows={false}
                    onClickItem={() => window.open(`/category/${outer.product_category_route}/${inner.product_route}`)}
                    showStatus={false}
                    transitionTime={300} 
                    autoPlay={auto}
                    selectedItem={0}
                    interval={1300}
                    stopOnHover={false}
                    infiniteLoop={true}
                    showIndicators={auto}
                    emulateTouch={auto}
                    swipeable={auto}
                    dynamicHeight={false} 
                    transitionEffect="fade"
                >*/}


        <img src={inner.product_img} alt="" onClick={() => window.open(`/category/${outer.product_category_route}/${inner.product_route}`)}/>


        {/*
            inner.side_img.map((sideimg) =>{
                return(
                    <img src={sideimg.img} alt="" /> 
                )
            })
        */}
      
       {/* </Carousel>*/}
        
       
    


             
        {!isProductInWish(outer.id, inner.id) ? (
            <span className="heart" onClick={()=>handlewish(outer.id,inner.id,inner.product_img,inner.product_name,inner.product_price)}><AiOutlineHeart /></span>
          ) : (
            <span className="heart" onClick={() => removeProductFromWish(outer.id, inner.id)}>
              <AiFillHeart  />
            </span>
          )}
     
   
    </div>
   
    <div className='product-content'>

    <div className="product-name"  onClick={() => window.open(`/category/${outer.product_category_route}/${inner.product_route}`)}>{inner.product_name}</div>



        <div className="rating">
            <StarRating />{inner.rating} ({formatReviewCount(inner.count)})
        </div>

        


  
    

    <div className="product-price">&#8377;{inner.product_price}  &nbsp;
    <span className="product-price-deleted">&#8377;<del>{inner.product_price_deleted}</del></span>
    </div>
    </div>

  






</div>




  )
}

export default MainProduct