import React, { useContext } from 'react'
import "./SingleImage.scss"
import MyContext from '../../../Common/Context/MyContext'

const SingleImage = ({img,sideimg}) => {

  const {handlebigover,setOvershow,handleMouseMove} = useContext(MyContext)

    const ImgOver = (sideimg) =>{
document.getElementById("change").src=sideimg
    }


  return (
    <>
     <img src={img} alt=""  id='change' onMouseMove={handleMouseMove}  onMouseEnter={handlebigover} onMouseLeave={() =>setOvershow(false)}/>

     <div className='box-main'>

     {sideimg.map((side) =>{
      return(
         <div className="box" onMouseOver={() => ImgOver(side.img)}>
         <img src={side.img} alt="" />
      </div>
      )
     })
    
     }
  
     </div>


     </>
  )
}

export default SingleImage